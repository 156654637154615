import { useStaticQuery, graphql } from 'gatsby';
import type { StrapiFaq, NormalizedStrapiFaq } from '@customTypes/strapiData';

export const useFaq = () => {
  const {
    allStrapiFaq: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiFaq {
        nodes {
          title
          rank
          items {
            title
            rank
            items {
              title
              answer
              rank
            }
          }
        }
      }
    }
  `);

  const normalizedNodes = nodes.map((node: StrapiFaq) => {
    const normalizedNodeGroups = node.items.map((nodeGroup) => ({
        groupTitle: nodeGroup.title,
        groupRank: nodeGroup.rank,
        groupItems: nodeGroup.items,
      }));

    const normalizedNode = {
      title: node.title,
      rank: node.rank,
      groups: normalizedNodeGroups.sort((a, b) => a.groupRank - b.groupRank),
    };

    return normalizedNode;
  });

  return (
    normalizedNodes.sort((a: NormalizedStrapiFaq, b: NormalizedStrapiFaq) => a.rank - b.rank) || []
  );
};
