import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';

import Layout from '@components/Layouts/Layout';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import { Accordion } from '@components/Blocks/Accordion';
import { Breadcrumbs } from '@components/Blocks/Breadcrumbs';
import { useFaq } from '@hooks/useFaq';
import type { NormalizedStrapiFaq } from '@customTypes/strapiData';

import styles from './Faq.module.scss';

export function Head() {
  return (
    <>
      <SEO title="FAQ" description="Ответы на часто задаваемые вопросы" />
      <YandexTableau />
    </>
  );
}

function FaqPage() {
  const faqItems = useFaq();
  const breadcrumbsPath = [
    { title: 'Главная', url: '/' },
    { title: 'FAQ', url: '' },
  ];
  const [selectedFaqSection, setSelectedFaqSection] = React.useState('');

  const handleSelectFaqSection = (sectionId: string) => {
    if (sectionId === selectedFaqSection) {
      return;
    }
    setSelectedFaqSection(sectionId);
  };

  return (
    <Layout>
      <div className={styles.breadcrumbsContainer}>
        <Breadcrumbs path={breadcrumbsPath} />
      </div>

      <div className={styles.faqPageWrapper}>
        <div className={styles.accordionMenu}>
          {faqItems.map((item: NormalizedStrapiFaq) => (
            <a
              className={cn({
                [styles.accordionMenuItem]: true,
                [styles.active]: selectedFaqSection === item.title,
              })}
              href={`#${item.title}`}
              onClick={() => handleSelectFaqSection(item.title)}
              key={`menuItem_${item.title}`}
            >
              {item.title}
            </a>
          ))}
        </div>

        <div className={styles.accordionWrapper}>
          <h1 className={styles.accordionHeader}>FAQ</h1>
          {faqItems.map((item: NormalizedStrapiFaq) => (
            <Accordion key={`faqItem_${item.title}`} title={item.title} items={item.groups} />
          ))}

          <div className={styles.contactUs}>
            <p>
              <Link to="/contacts">Свяжитесь с нами</Link>, если не нашли ответа, хотите оставить
              предложение или отзыв.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
